import * as c from './constants'
import { AreaCleaningStatus, Occupancy } from './dataObjects'
import * as colors from './colors'

import moment from 'moment-timezone'

import { iOSColors } from './react-native-typography'

interface IIconObj {
    source: string | null | undefined
    width?: number | null | undefined
    height?: number | null | undefined
}

export function pickCleaningStatusColor(cleaningStatus: AreaCleaningStatus, occupancy?: Occupancy) {
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (occupancy) {
            if (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80) {
                return colors.rose_unclean
            }
        }
        return colors.red_spectaflow
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        return colors.yello_spectaflow
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        return colors.curry_yellow_inspection
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        return iOSColors.green
    }
    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        return colors.blue_spectaflow
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        return iOSColors.midGray
    }
    if (cleaningStatus === c.CLEANING_STATUS_NO_SERVICE) {
        return colors.teal_sweeply
    }
    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        return colors.purple_spectaflow
    }
    if (cleaningStatus === c.CLEANING_STATUS_ALL) {
        return iOSColors.black
    }
    return iOSColors.black
}

export function pickGuestCleaningActionIcon(occupancy: Occupancy) {
    if (!occupancy) {
        return null
    }
    const iconObj: IIconObj = { source: null, width: null, height: null }

    if (occupancy === c.OCCUPANCY_STAYOVER) {
        iconObj.width = 16
        iconObj.height = 16
        iconObj.source = c.IMG_CLEANING_TASK_TIDY_ROOM_WHITE

        return iconObj
    } else if (occupancy === c.OCCUPANCY_STAYOVER_80) {
        iconObj.width = c.ICON_PREPARE_ROOM.width
        iconObj.height = c.ICON_PREPARE_ROOM.height
        iconObj.source = c.IMG_CLEANING_TASK_PREPARE_ROOM_WHITE

        return iconObj
    } else if (occupancy === c.OCCUPANCY_VACANT) {
        iconObj.width = 16
        iconObj.height = 16
        iconObj.source = c.IMG_CLEANING_STATUS_NO_SERVICE_WHITE

        return iconObj
    } else if (occupancy === c.OCCUPANCY_CHECKOUT || occupancy === c.OCCUPANCY_TURNOVER) {
        iconObj.width = c.ICON_CHECKOUT_CLEANING.width
        iconObj.height = c.ICON_CHECKOUT_CLEANING.height
        iconObj.source = c.IMG_CLEANING_TASK_CHECKOUT_CLEANING_WHITE

        return iconObj
    } else {
        return null
    }
}

export function isToday(date: number) {
    const d1 = moment().startOf('day').valueOf()

    const d2 = moment(date).startOf('day').valueOf()

    return d1 === d2
}
