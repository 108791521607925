import * as colors from './colors'
import * as c from './constants'
import { MiniBrandStruct, SPECTATEST_CONFIG, SWEEPLY_CONFIG } from './mini-brand'
import { CURRENT_BRAND, CURRENT_OS } from './config-env'
import { OrgStruct, UserStruct } from './firestore-structs'

import { Firebase } from './firebase'

export interface Brand {
    key: string
    name: string
    appLogo: any
    webLogo: any
    webNavBarLogo: any
    openWebNavBarLogo: any
    navBarColor: string
    colorClass: string
    navbarIcons: {
        myTasks: string
        cleaning: string
        camera: string
        issues: string
    }
    notificationColor: string
    urls: {
        marketingSite: string
        webappSite: string
        appStore: string
        playStore: string
        mobileHowTo: string
        helpEmail: string
        guide: string
    }
    intercomAppId?: string
    contactUsLink: string
    firebase?: MiniBrandStruct
}

type LogoInfo = { img: any; style: { width: number; height: number }; url: string }

type IssueTypeDescription = {
    maintenance: string
    money: string
}
export const SWEEPLY_BRAND: Brand = {
    key: 'sweeply',
    name: 'Sweeply',
    appLogo: c.IMG_BRAND_SWEEPLY_OPEN_NAVBAR_LOGO,
    webLogo: c.IMG_BRAND_SWEEPLY_OPEN_NAVBAR_LOGO,
    webNavBarLogo: c.IMG_BRAND_SWEEPLY_NAVBAR_LOGO,
    openWebNavBarLogo: c.IMG_BRAND_SWEEPLY_OPEN_NAVBAR_LOGO,
    navBarColor: colors.red_sweeply,
    colorClass: 'sweeply-red',
    navbarIcons: {
        myTasks: require('./img/brand/sweeply/my-tasks-selected.png'),
        cleaning: require('./img/brand/sweeply/cleaning-selected.png'),
        camera: require('./img/brand/sweeply/camera-selected.png'),
        issues: require('./img/brand/sweeply/issues-selected.png')
    },
    notificationColor: colors.white,
    urls: SWEEPLY_CONFIG.urls,
    intercomAppId: 'trrzt06t',
    contactUsLink: 'mailto:support@getsweeply.com'
}

export const SPECTATEST_BRAND: Brand = {
    key: 'spectatest',
    name: 'Spectatest',
    appLogo: c.IMG_BRAND_SPECTATEST_LOGO,
    webLogo: c.IMG_BRAND_SPECTATEST_LOGO,
    webNavBarLogo: c.IMG_BRAND_SPECTATEST_NAVBAR_ICON,
    openWebNavBarLogo: c.IMG_BRAND_SPECTATEST_NAVBAR_ICON,
    navBarColor: colors.yello_spectaflow,
    colorClass: 'specta-yellow',
    navbarIcons: {
        myTasks: require('./img/brand/spectatest/my-tasks-selected.png'),
        cleaning: require('./img/brand/spectatest/cleaning-selected.png'),
        camera: require('./img/brand/spectatest/camera-selected.png'),
        issues: require('./img/brand/spectatest/issues-selected.png')
    },
    notificationColor: colors.red_spectaflow,
    urls: SPECTATEST_CONFIG.urls,
    intercomAppId: 'qge8o33c',
    contactUsLink: 'mailto:support@spectaflow.com'
}

export function getBrand(): Brand {
    let brand: Brand

    if (CURRENT_BRAND === SWEEPLY_BRAND.key) {
        brand = SWEEPLY_BRAND
        brand.firebase = SWEEPLY_CONFIG
    } else {
        brand = SPECTATEST_BRAND
        brand.firebase = SPECTATEST_CONFIG
    }
    return brand
}

export async function BRAND_ORGANIZATION_NAME(firebase: Firebase, currentUser: UserStruct): Promise<string> {
    let orgName = 'Spectaflow'
    const orgSnap = await firebase.firestore().collection<OrgStruct>('organizations').doc(currentUser.organizationKey).get()
    const organization = orgSnap.data()
    if (organization?.name) {
        orgName = organization.name
    }
    return orgName
}

export function getLoginLogo() {
    if (CURRENT_BRAND === SWEEPLY_BRAND.key) {
        if (CURRENT_OS === 'ios' || CURRENT_OS === 'android') {
            return {
                source: SWEEPLY_BRAND.appLogo,
                style: {
                    width: 155,
                    marginLeft: -10
                }
            }
        }
        return {
            source: SWEEPLY_BRAND.webLogo,
            style: {
                width: 155
            }
        }
    }

    if (CURRENT_BRAND === SPECTATEST_BRAND.key) {
        if (CURRENT_OS === 'ios' || CURRENT_OS === 'android') {
            return {
                source: SPECTATEST_BRAND.appLogo,
                style: {
                    width: 170,
                    height: 40
                }
            }
        }
        return {
            source: SPECTATEST_BRAND.webLogo,
            style: {
                width: 180
            }
        }
    }
    return null
}

export function getNavBarNOAuthLogo(): LogoInfo {
    const logo: LogoInfo = {
        img: SWEEPLY_BRAND.openWebNavBarLogo,
        style: { width: 151, height: 29 },
        url: SWEEPLY_BRAND.urls.webappSite
    }

    if (CURRENT_BRAND === SPECTATEST_BRAND.key) {
        logo.img = SPECTATEST_BRAND.openWebNavBarLogo
        logo.style = {
            width: 151,
            height: 29
        }
        logo.url = SPECTATEST_BRAND.urls.webappSite
    } else if (CURRENT_BRAND === SWEEPLY_BRAND.key) {
        logo.img = SWEEPLY_BRAND.openWebNavBarLogo
        logo.style = {
            width: 220,
            height: 42
        }
        logo.url = SWEEPLY_BRAND.urls.webappSite
    }
    return logo
}

export function getNavBarAuthLogo(): LogoInfo {
    const logo: LogoInfo = {
        img: SWEEPLY_BRAND.webNavBarLogo,
        style: {
            width: 31,
            height: 32
        },
        url: SWEEPLY_BRAND.urls.webappSite
    }
    if (CURRENT_BRAND === SPECTATEST_BRAND.key) {
        logo.img = SPECTATEST_BRAND.webNavBarLogo
        logo.style = {
            width: 35,
            height: 35
        }
        logo.url = SPECTATEST_BRAND.urls.webappSite
    } else if (CURRENT_BRAND === SWEEPLY_BRAND.key) {
        logo.img = SWEEPLY_BRAND.webNavBarLogo
        logo.style = { width: 50, height: 26 }
        logo.url = SWEEPLY_BRAND.urls.webappSite
    }
    return logo
}
