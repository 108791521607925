import * as c from '@shared/constants'

export function pickOccupancyIcon(type) {
    let imgName = 'booking-note'
    if (type === c.OCCUPANCY_STAYOVER_80) {
        imgName = 'bed'
    } else if (type === c.OCCUPANCY_STAYOVER) {
        imgName = 'broom'
    } else if (type === c.OCCUPANCY_CHECKOUT) {
        imgName = 'suitcase'
    } else if (type === c.OCCUPANCY_CHECKIN) {
        imgName = 'check-in icon-lg'
    } else if (type === c.OCCUPANCY_VACANT) {
        imgName = 'vacant'
    } else if (type === c.OCCUPANCY_TURNOVER) {
        imgName = 'turnover icon-xl'
    }

    return imgName
}

export const pickCleaningStatusColorClass = (cleaningStatus, occupancy) => {
    if (cleaningStatus === null && occupancy === null) {
        return 'white'
    }
    if (cleaningStatus === null) {
        return 'dark'
    }
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        if (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80) {
            return 'specta-pink'
        } else {
            return 'specta-red'
        }
    }
    if (cleaningStatus === c.CLEANING_STATUS_PREPARING || cleaningStatus === c.CLEANING_STATUS_PREPARING_PAUSE) {
        return 'specta-yellow'
    }
    if (cleaningStatus === c.CLEANING_STATUS_CLEAN) {
        return 'specta-green'
    }
    if (cleaningStatus === c.CLEANING_STATUS_INSPECTION) {
        return 'specta-gold'
    }
    if (cleaningStatus === c.CLEANING_STATUS_WAITING_FOR_CHECKOUT) {
        return 'specta-purple'
    }

    if (cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB) {
        return 'specta-blue'
    }

    if (cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE) {
        return 'specta-gray-mid'
    }

    return null
}
