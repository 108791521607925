/**
 * This can be useful to quickly debug arrays:
 * ``` ['a','b'].map(debug(item => `Item is:${item}`)).map(...).filter(...)
 * @param b
 */
export const debug = <T>(b: (msg: T) => string) => (a: T): T => {
    console.debug(b(a))
    return a
}
export const isNotNull = <T>(b: T | null | undefined): b is T => {
    return b !== null && typeof b !== 'undefined'
}
