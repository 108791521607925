export const ISSUE_OPEN = 'open'
export const ISSUE_ASSIGNED = 'assigned'
export const ISSUE_COMPLETE = 'completed'
export const ISSUE_DELETED = 'deleted'

export const TEXT_ISSUE = 'text-issue'

export const ISSUE_MAINTENANCE = 'maintenance'

export const CLEANING_STATUS_DIRTY = 'dirty'
export const CLEANING_STATUS_PREPARING = 'preparing'
export const CLEANING_STATUS_PREPARING_PAUSE = 'preparing-pause'
export const CLEANING_STATUS_INSPECTION = 'inspection'
export const CLEANING_STATUS_CLEAN = 'clean'
export const CLEANING_STATUS_DO_NOT_DISTURB = 'do-not-disturb'
export const CLEANING_STATUS_NO_SERVICE = 'no-cleaning-service'
export const CLEANING_STATUS_OUT_OF_SERVICE = 'no-service'
export const CLEANING_STATUS_WAITING_FOR_CHECKOUT = 'waiting-for-checkout'
export const CLEANING_STATUS_ALL = 'all'
export const CLEANING_STATUS_OTHER = 'other'

export const OCCUPANCY_CHECKOUT = 'checkout'
export const OCCUPANCY_TURNOVER = 'turnover'
export const OCCUPANCY_CHECKIN = 'checkin'
export const OCCUPANCY_VACANT = 'vacant'
export const OCCUPANCY_STAYOVER = 'stayover'
export const OCCUPANCY_STAYOVER_80 = 'stayover-80'
export const OCCUPANCY_ALL = 'all'

export const AREA_GROUPS_ALL = 'All'

export const TASK_OPEN = 'open'
export const TASK_ASSIGNED = 'assigned'
export const TASK_COMPLETE = 'completed'

export const TASK_TYPE_HOUSEKEEPING = 'cleaning'
export const TASK_TYPE_ISSUE = 'issue'
export const TASK_TYPE_GENERAL = 'general'

export const TASK_CLEANING_DEFAULT_NAME = 'Clean & prepare'

export const DATE_FORMAT_WITH_WEEK_DAY = 'ddd / MMM D / YYYY'
export const ICON_PREPARE_ROOM = { width: 16, height: 13.7 }
export const ICON_CHECKOUT_CLEANING = { width: 16, height: 14.3 }
export const HASHTAGS_DEFAULT = ['#housekeeping🧹', '#improvement⭐️', '#lost&found😢', '#maintenance🛠', '#minibar🍷']

export const ACTIVITY_TYPE_CLEANING_STATUS = 'cleaning-status'
export const ACTIVITY_TYPE_DAILY_COMMENT = 'daily-comment'
export const ACTIVITY_TYPE_AREA_NOTE = 'area-note'
export const ACTIVITY_TYPE_ASSIGNMENT = 'assignment'
export const ACTIVITY_TYPE_CLEANING_SCHEDULE = 'cleaning-schedule'

export const BOOKING_STATUS_BOOKED = 'Booked'
export const BOOKING_STATUS_BLOCKED = 'Blocked'

export const BOOKING_NOTE = 'booking-note'
export const NON_EXIST = 'non-exist'

export const DEFAULT_DIAL_CODE = '+354'
