export const IMG_BRAND_SWEEPLY_NAVBAR_LOGO = require('./img/brand/sweeply/icon.svg')
export const IMG_BRAND_SWEEPLY_OPEN_NAVBAR_LOGO = require('./img/brand/sweeply/logo-long.svg')

export const IMG_BRAND_SPECTATEST_LOGO = require('./img/brand/spectatest/logo.svg')
export const IMG_BRAND_SPECTATEST_NAVBAR_ICON = require('./img/brand/spectatest/icon.svg')

export const IMG_CLEANING_STATUS_NO_SERVICE_WHITE = require('./img/cleaning-status/ct-no-service-white.svg')
export const IMG_CLEANING_TASK_TIDY_ROOM_WHITE = require('./img/cleaning-status/ct-tidy-room-white.svg')
export const IMG_CLEANING_TASK_PREPARE_ROOM_WHITE = require('./img/cleaning-status/ct-prepare-room-white.svg')
export const IMG_CLEANING_TASK_CHECKOUT_CLEANING_WHITE = require('./img/cleaning-status/checkout-cleaning-white.svg')
