import React from 'react'
import TracesLayout from 'app/components/layouts/TracesLayout'
import { SubscriptionProvider } from 'app/modules/Subscriptions/SubscriptionContext'
import { Route, Switch } from 'react-router-dom'
import BasePage from '../BasePage'
import { TASKBOARD } from 'app/navigation/url-constants'
import withErrorCatching from 'app/utils/hocs/withErrorCatching'
import { CatchError } from 'app/types'
import { logout } from 'app/utils/firebase'
import Loader from 'app/components/loaders/Loader'
import { useTracesIntegrationParams } from './hooks'
import { useFeatureToggle } from 'app/features'

function TaskboardStandalone() {
    return <div>Please contact Sweeply support to upgrade your account.</div>
}

function TracesRouter({ catchError }: { catchError: CatchError }) {
    const [loading, error] = useTracesIntegrationParams()
    const { isFeatureOn } = useFeatureToggle()
    const snap = isFeatureOn('snap')

    error &&
        catchError(error as Error, {
            button: { text: 'Logout', onClick: logout }
        })
    return (
        <TracesLayout>
            {/* FIXME - fix this props type */}
            {/* @ts-ignore */}
            <SubscriptionProvider location={location}>
                <Switch>
                    {!snap && <Route exact path={TASKBOARD.APALEO} render={() => (loading ? <Loader /> : <TaskboardStandalone />)} />}
                    <BasePage />
                </Switch>
            </SubscriptionProvider>
        </TracesLayout>
    )
}

export default withErrorCatching(TracesRouter)
