import React, { useContext, useEffect, useMemo } from 'react'
import { AuthContext } from '../../../Auth/AuthContext'
import LoadingView from '../../../../utils/loading-view'
import moment from 'moment-timezone'
import { ReportsContext } from '../../ReportsContext'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import CleaningSummary from './components/CleaningSummary'
import ReportTableHeaders from './components/ReportTableHeaders'
import { dateFilterType as dft, reportTableDateHeaders, reportTablePeriodHeaders, tasksData } from './atoms.js'
import { reportTableData } from './selectors.js'
import UserRow from './components/UserRow'
import { filterByAreaGroupAccess } from '@shared/area-groups-helpers'
import { getHousekeepingTasksQuery, getTasks } from '@shared/task-data'
import firebase from '../../../../utils/firebase'
import { TASK_COMPLETE } from '@shared/txt-constants'

export function ReportsTable() {
    const { listLoading, setListLoading, queryParams } = useContext(ReportsContext)
    const { currentOrganization, currentUser } = useContext(AuthContext)
    const dateHeaders = useRecoilValue(reportTableDateHeaders)
    const periodHeaders = useRecoilValue(reportTablePeriodHeaders)
    // const [reports, setReports] = useRecoilState(reportsData)
    const [, setTasks] = useRecoilState(tasksData)
    const [, setDateFilterType] = useRecoilState(dft)
    const tableData = useRecoilValue(reportTableData)
    const dateFilter = useRecoilValue(dft)

    const { userRows, summaryByCleaningType } = tableData

    useEffect(() => {
        setDateFilterType(queryParams.filter.dateFilterType)
    }, [queryParams.filter.dateFilterType])

    const reportsUIProps = useMemo(() => {
        return {
            listLoading: listLoading,
            setListLoading: setListLoading
        }
    }, [listLoading, setListLoading])

    const getData = async () => {
        reportsUIProps.setListLoading(true)

        let tasks = await getTasks(
            getHousekeepingTasksQuery(
                firebase,
                currentOrganization.key,
                [
                    moment(queryParams.filter.startDate).valueOf(),
                    moment(queryParams.filter.endDate || queryParams.filter.startDate).valueOf()
                ],
                [TASK_COMPLETE]
            )
        )

        tasks = tasks.filter(task => {
            const filteredArea = filterByAreaGroupAccess(currentUser, [task.area])
            return filteredArea.length > 0
        })

        tasks = tasks.filter(t => t.cleaning && t.cleaning.end)

        setTasks(tasks)
        // setReports(taskUsers)
        setTimeout(() => {
            reportsUIProps.setListLoading(false)
        }, 500)
    }

    async function handleGetData() {
        await getData()
    }

    useEffect(() => {
        handleGetData()
    }, [queryParams.filter.startDate, queryParams.filter.endDate])

    const headersCondition = dateFilter === 'date' ? dateHeaders : periodHeaders

    const userRowsCondition = !reportsUIProps.listLoading && (
        <tbody>
            {userRows.map((userRow, index) => {
                const initials = userRow.user.initials //reports[index].initials
                return <UserRow key={userRow.user + index} userRow={userRow} initials={initials} />
            })}
        </tbody>
    )

    const cleaningSummaryCondition = !reportsUIProps.listLoading && summaryByCleaningType && (
        <CleaningSummary summaryByCleaningType={summaryByCleaningType} />
    )

    return (
        <>
            <div className="table-responsive">
                <table className="table table-hover table-head-custom table-vertical-center">
                    <ReportTableHeaders headers={headersCondition} />

                    {userRowsCondition}
                </table>
            </div>
            {cleaningSummaryCondition}
            {!reportsUIProps.listLoading && tableData.length === 0 && <h3 className="mt-20 text-center">No records found</h3>}
            {reportsUIProps.listLoading && (
                <div className="mt-20">
                    <LoadingView />
                </div>
            )}
        </>
    )
}
