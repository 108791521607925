import * as colors from '@shared/colors'
import { iOSColors } from '@shared/react-native-typography'
import { CSSObject } from 'styled-components'

export const selectStyles = ({ width, inputHidden }: { width?: number; inputHidden?: boolean }) => ({
    control: (styles: object) => ({
        ...styles,
        width: width ? width : 560,
        borderWidth: 1,
        borderColor: '#e4e6ef',
        borderRadius: 6,
        boxShadow: 'none',
        ':hover': {
            borderWidth: 1,
            borderColor: colors.green_spectaflow,
            borderRadius: 6
        },
        ...(inputHidden && {
            height: 0,
            minHeight: 0,
            border: 'none'
        })
    }),
    option: (styles: object, state: { isDisabled: boolean; isFocused: boolean; isSelected: boolean }): CSSObject => {
        const { isSelected, isFocused, isDisabled } = state
        return {
            ...styles,
            backgroundColor: isSelected || isFocused ? iOSColors.customGray : '',
            color: colors.textPrimary,
            cursor: isDisabled ? 'default' : 'pointer'
        }
    },
    placeholder: (styles: object) => ({
        ...styles,
        color: '#bab9c7',
        ...(inputHidden && {
            display: 'none'
        })
    }),
    indicatorSeparator: (styles: object) => ({
        ...styles,
        backgroundColor: '#f4f5f6',
        width: 0
    }),
    indicatorsContainer: (styles: object) => ({
        ...styles,
        ...(inputHidden && {
            display: 'none'
        })
    }),
    valueContainer: (base: object) => ({
        ...base,
        maxHeight: 200,
        ...(inputHidden && {
            height: 0,
            width: 0,
            opacity: 0
        })
    }),
    menuList: (base: object) => ({
        ...base,
        padding: 0,
        minHeight: 200,
        height: 200
    })
})
