import moment from 'moment-timezone'
import { AreaStruct, BookingStruct, OrgStruct } from './firestore-structs'
import { Firebase } from './firebase'

import * as c from './constants'

export async function getBookings(
    firebase: Firebase,
    organizationKey: string,
    areaKey = null,
    arrivalFrom = null,
    arrivalTo = null,
    departureFrom = null,
    departureTo = null
): Promise<BookingStruct[]> {
    let bookingQuery = firebase.firestore().collection('bookings').where('organizationKey', '==', organizationKey)

    if (areaKey) {
        bookingQuery = bookingQuery.where('areaKey', '==', areaKey)
    }

    if (arrivalFrom) {
        bookingQuery = bookingQuery.where('checkinDate', '>=', arrivalFrom)
    }

    if (arrivalTo) {
        bookingQuery = bookingQuery.where('checkinDate', '<=', arrivalTo)
    }

    if (departureFrom) {
        bookingQuery = bookingQuery.where('checkoutDate', '>=', departureFrom)
    }

    if (departureTo) {
        bookingQuery = bookingQuery.where('checkoutDate', '<=', departureTo)
    }

    const bookingsRef = await bookingQuery.get()

    let bookings = bookingsRef ? bookingsRef.docs.map(b => b.data() as BookingStruct) : []

    bookings = bookings.filter(b => b.status && b.status.toLowerCase() !== 'cancelled')

    return bookings
}

export async function createBooking(
    firebase: Firebase,
    from: string,
    to: string,
    area: AreaStruct,
    ext: Partial<BookingStruct>,
    org: Pick<OrgStruct, 'timezone'>
): Promise<Partial<BookingStruct>> {
    const checkinDate = moment.tz(from, org.timezone).startOf('day')
    const checkoutDate = moment.tz(to, org.timezone).startOf('day')
    const stayNights = checkoutDate.diff(checkinDate, 'days')

    const seed = {
        adults: ext.adults,
        areaKey: area.key,
        bookingDates: [...Array(stayNights + 1).keys()].map(day =>
            moment.tz(checkinDate, org.timezone).add(day, 'days').valueOf().toString()
        ),
        bookingId: ext.bookingId,
        checkinDate: checkinDate.valueOf(),
        checkoutDate: checkoutDate.valueOf(),
        children: ext.children,
        guestCheckedIn: ext?.guestCheckedIn ?? false,
        guestCheckedOut: ext?.guestCheckedOut ?? false,
        guestName: ext.guestName,
        lastModified: Date.now(),
        notes: ext.notes || '',
        organizationKey: area.organizationKey,
        status: ext?.status ?? (c.BOOKING_STATUS_BOOKED as BookingStruct['status'])
    }
    const booking = firebase.firestore().collection('bookings').doc()
    await booking.set(seed)
    return seed
}

export function getBookingsQuery(firebase: Firebase, organizationKey: string, date: number, areaKey: string | null = null) {
    let bookingsQuery = firebase
        .firestore()
        .collection('bookings')
        .where('organizationKey', '==', organizationKey)
        .where('bookingDates', 'array-contains', date.toString())

    if (areaKey) {
        bookingsQuery = bookingsQuery.where('areaKey', '==', areaKey)
    }

    return bookingsQuery
}

export const getBookings2 = async (
    firebase: Firebase,
    organizationKey: string,
    filter: { [filter: string]: { operator: '<=' | '>=' | '==' | 'array-contains-any'; value: string | number | boolean | string[] } }
) => {
    let bookingsQuery = firebase.firestore().collection('bookings').where('organizationKey', '==', organizationKey)
    for (const [field, value] of Object.entries(filter)) {
        if (field.startsWith('_')) {
            bookingsQuery = bookingsQuery.where(field.substring(1), value.operator, value.value)
        } else {
            bookingsQuery = bookingsQuery.where(field, value.operator, value.value)
        }
    }
    const bookingsSnap = await bookingsQuery.get()
    return bookingsSnap.docs.map(v => v.data() as BookingStruct)
}

export const getNextBooking = async (
    firebase: Firebase,
    areaKey: string,
    organizationKey: string,
    date: number
): Promise<BookingStruct[]> => {
    const tomorrow = moment(date).add(1, 'days')

    const bookingsQuery = firebase
        .firestore()
        .collection('bookings')
        .where('organizationKey', '==', organizationKey)
        .where('areaKey', '==', areaKey)
        .where('checkoutDate', '>=', tomorrow.valueOf())
        .orderBy('checkoutDate', 'asc')

    const bookingsRef = await bookingsQuery.limit(2).get() // we get two bookings since the first one might be one that is currently checked in

    let bookings = bookingsRef.docs.map(b => b.data() as BookingStruct)

    bookings = bookings.filter(b => b.status && b.status.toLowerCase() !== 'cancelled').filter(b => b.checkinDate > date)

    return bookings
}

export const getBookingByKey = async (firebase: Firebase, bookingKey: string) => {
    const bookingRef = await firebase.firestore().collection('bookings').doc(bookingKey).get()
    if (!bookingRef.exists) {
        throw new Error(`Booking not found ${bookingKey}`)
    }
    return bookingRef.data() as BookingStruct
}

export const getBookingsByGroupId = async (firebase: Firebase, organizationKey: string, groupId: string) => {
    if (!organizationKey?.trim() || !groupId?.trim()) {
        throw new Error('Invalid organizationKey or groupId')
    }
    const bookingsQuery = firebase
        .firestore()
        .collection('bookings')
        .where('organizationKey', '==', organizationKey)
        .where('_external.groupID', '==', groupId)
    try {
        const bookingsSnap = await bookingsQuery.get()
        return bookingsSnap.docs.map(v => v.data() as BookingStruct)
    } catch (error) {
        console.error('Failed to fetch bookings by groupId:', error)
        throw error
    }
}
